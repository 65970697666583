.admin-dashboard {
	height: 100vh;
	background-color: #f0f4f8; /* или другой цвет, сочетающийся со светлой темой */
}

a {
	color: #1a237e; /* или другой цвет, подходящий под вашу палитру */
	text-decoration: none; /* убирает стандартное подчеркивание */
}

.admin-dashboard a:hover {
	text-decoration: underline; /* добавляет подчеркивание при наведении */
}

.stat-block {
	box-sizing: border-box;
	width: calc(25% - 15px);
	height: auto;
	padding: 40px 20px;
	border-radius: 2px;
	display: flex;
	align-items: stretch;
	justify-content: space-between;
	gap: 20px;
	flex-direction: column;
	line-height: 1.1;
	color: #fff;
	text-align: center;
	transition: all 0.3s ease;
	text-decoration: none;
}

.stat-block:hover {
	transform: translateY(-5px);
	transition: all 0.3s ease;
	text-decoration: none;
	color: #fff;
}

.stat-block p {
	text-transform: uppercase;
	margin: 0;
}

.stat-block p span {
	color: #999;
	font-size: 12px;
	text-transform: none;
}

.stat-block h1 {
	margin: 0;
}

.row {
	margin-top: 0;
	display: flex;
	align-items: center;
	justify-content: space-between;
	background-color: #e4e4e4;
	border-radius: 2px;
	padding: 0 20px;
}

@media (max-width: 768px) {
	.stat-block {
		width: calc(50% - 10px);
	}
}

@media (max-width: 576px) {
	.stat-block {
		width: 100%;
	}
}
