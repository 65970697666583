.wrapper {
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;
	height: 100vh;
	text-align: left;
}

.wrapper h1 {
	font-size: 30px;
	margin: 0;
	margin-bottom: 20px;
}

.wrapper form {
	min-width: 500px;
}

.wrapper > div {
	width: 100%;
}

.error {
	color: red;
	font-size: 12px;
	margin-top: 3px;
}

.input {
	width: 100%;
}

.wrapper button {
	width: 100%;
}

.loaderWrapper {
	display: flex;
	align-items: center;
	justify-content: center;
}

@media screen and (max-width: 576px) {
	.wrapper form {
		min-width: auto;
		width: 90%;
	}
}

@media (max-width: 768px) {
	.wrapper {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.wrapper .text {
		text-align: center;
		margin-left: 0;
		padding: 0 20px;
	}
}
