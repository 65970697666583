.welcome {
	position: relative;
	text-align: center;
	color: #fff; /* Цвет текста, так как фон темный */
	height: 100vh;
	background-image: url("../images/space.jpeg");
	background-size: cover;
	background-position: center;
}

.welcome-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8);
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.welcome-content {
	z-index: 1;
	padding: 0 35px;
}

.welcome h1 {
	font-size: 3em;
	text-decoration: underline;
}

.welcome p {
	max-width: 600px;
	margin: 20px auto;
}

.demo-button {
	background-color: #3b73f5;
	color: #fff;
	padding: 20px 60px;
	border: none;
	border-radius: 40px;
	font-size: 0.9em;
	cursor: pointer;
	transition: background-color 0.3s;
	text-decoration: none;
}

.demo-button:hover {
	background-color: #2d5bc5;
}

.welcome-content .icons {
	margin-top: 5%;
	display: flex;
	justify-content: center;
	margin-bottom: 5%;
}

.icon {
	text-align: center;
	margin: 10px;
	width: 30%;
}

.icon svg {
	font-size: 4em; /* Размер иконок */
	margin-bottom: 10px;
}

.banner {
	position: fixed;
	font-size: 18px;
	top: 0;
	left: 0;
	width: 100%;
	background-color: #3b73f5;
	color: #fff;
	padding: 10px;
	display: flex;
	justify-content: space-between;
	align-items: center;
	box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.1);
	animation: slideIn 0.5s ease-in-out;
	z-index: 5;
	box-sizing: border-box;
}

.banner div {
	gap: 10px;
	display: flex;
	justify-content: center;
	align-items: center;
}

.order-button {
	background-color: #fff;
	color: #3b73f5;
	border: none;
	padding: 10px 20px;
	border-radius: 5px;
	cursor: pointer;
	border-radius: 20px;
}

.close-icon {
	cursor: pointer;
	font-size: 1.5em; /* Увеличиваем размер крестика */
}

@keyframes slideIn {
	from {
		transform: translateY(-100%);
	}
	to {
		transform: translateY(0);
	}
}

.contact-form {
	position: relative;
	text-align: center;
	color: #fff;
	height: 100vh;
	background-image: url("../images/space.jpeg"); /* Укажите путь к вашему изображению */
	background-size: cover;
	background-position: center;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-overlay {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(0, 0, 0, 0.8); /* Полупрозрачный фон */
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}

.contact-content {
	z-index: 1;
	width: 60%; /* Ширина контента */
	max-width: 500px; /* Максимальная ширина */
}

.main-phone-input,
.main-name-input,
.main-submit-button {
	background-color: white;
	border-radius: 5px;
	width: 100%;
}

.main-phone-input,
.main-name-input {
	margin-bottom: 10px !important;
}

.main-submit-button {
	background-color: #3b73f5;
	color: #fff;
	padding: 18px 60px;
	border: none;
	border-radius: 10px;
	font-size: 16px;
	cursor: pointer;
	transition: background-color 0.3s;
	text-decoration: none;
}

.main-submit-button:hover {
	background-color: #2d5bc5;
}

.contact-info {
	margin-top: 10%;
	height: 60vh;
	text-align: center;
}

.contact-info h2 {
	color: #333; /* черный цвет заголовка */
	font-size: 3em;
}

.contact-info a {
	text-decoration: underline;
	font-size: 2em;
	color: #333; /* черный цвет для ссылок */
	display: block; /* чтобы разместить номер телефона и почту друг под другом */
	margin-bottom: 10px; /* отступ между номером телефона и почтой */
}

.contact-info .icon-container {
	display: flex;
	justify-content: center;
}

.contact-info a:hover {
	color: #555; /* изменение цвета ссылок при наведении */
}

.contact-info .icon-container svg {
	width: 36px;
	height: 36px;
	margin-right: 10px;
	vertical-align: middle;
}

.contact-info .icon-container a {
	text-decoration: none;
	color: #333;
}

.contact-info .icon-container a:hover {
	color: #555;
}

.pricing {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	height: 100vh;
}

.pricing h2 {
	color: #333;
	font-size: 2em;
	margin: 10px 0 0 0;
}

.pricing p {
	color: #666;
	text-align: center;
	margin: 10px 0 0 0;
}

.card {
	text-align: center;
	background-color: #f9f9f9;
	padding: 20px;
	border-radius: 8px;
	box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
	margin: 0 auto; /* Центрируем карточку по горизонтали */
	margin-top: 10px;
	max-width: 400px; /* Максимальная ширина карточки */
}

.card p {
	font-size: 1.2em;
	font-weight: bold;
	margin-bottom: 10px;
}

.card .price {
	font-size: 2em;
	color: #3b73f5; /* Цвет цены */
}

.card ul {
	padding: 0;
	text-align: left;
	list-style-type: none;
}

.card li {
	margin-bottom: 10px;
}

.card li::before {
	content: "∙";
	padding-right: 5px;
}

.qr-design {
	/* предполагая, что это родительский элемент */
	display: flex;
	justify-content: center; /* центрирует по горизонтали */
	align-items: center; /* центрирует по вертикали, если нужно */
	flex-direction: column;
}

.qr-design h2 {
	color: #333;
	font-size: 2em;
	margin-bottom: 0;
}

.qr-design p {
	color: #666;
	text-align: center;
}

.qr-carousel-container {
	width: 40%; /* блок будет занимать 50% ширины родительского элемента */
	height: auto; /* высота будет адаптироваться к содержимому */
	margin-bottom: 20px;
}

.qr-carousel-container img {
	height: 400px !important;
}

.qr-carousel-container img {
	margin: 0 !important;
}

.widget-description {
	padding: 20px;
	text-align: center;
	background-color: #d4daff;
	display: flex; /* Add this if not already there */
	flex-direction: column; /* Stack children vertically */
	align-items: center; /* Center children horizontally */
}

.widget-description h2 {
	color: #333;
	font-size: 2em;
	margin-top: 0;
	margin-bottom: 10px;
}

.widget-description p {
	color: #666;
	margin-bottom: 30px;
}

.widget-wrapper {
	width: 50%;
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 20px;
}

.widget-wrapper img {
	width: calc(50% - 10px);
}

.divider {
	display: flex;
	align-items: center;
	text-align: center;
	margin: 20px 0;
}

.divider::before,
.divider::after {
	content: "";
	flex: 1;
	border-bottom: 2px solid #fff;
}

.divider::before {
	margin-right: 10px;
}

.divider::after {
	margin-left: 10px;
}

@media (max-width: 1366px) {
	.pricing {
		height: auto; /* Убираем фиксированную высоту для мобильных устройств */
		padding-bottom: 20px; /* Добавляем немного отступа снизу */
	}

	.contact-info {
		height: auto;
		padding-bottom: 20px;
	}

	.contact-content {
		z-index: 1;
		width: 90%; /* Ширина контента */
	}

	.qr-carousel-container img {
		height: 400px !important;
	}

	.card {
		margin: 20px; /* Добавляем отступы с боков и сверху для карточки */
	}
}

@media (max-width: 1200px) {
	.qr-carousel-container {
		width: 50%;
	}
}

@media (max-width: 992px) {
	.qr-carousel-container,
	.widget-wrapper {
		width: 60%;
	}
}

@media (max-width: 768px) {
	.qr-carousel-container,
	.widget-wrapper {
		width: 75%;
	}
}

@media (max-width: 500px) {
	.qr-carousel-container,
	.widget-wrapper {
		width: 90%;
	}
	.widget-wrapper {
		flex-direction: column;
		align-items: center;
	}
	.widget-wrapper img {
		width: 100%;
	}
	.qr-carousel-container img {
		height: 300px !important;
	}
}

@media (max-width: 576px) {
	.icon {
		width: 50%;
	}
}
