:root {
	--text-color: #333333;
	--bg-color: #ffffff;
}
@media (prefers-color-scheme: dark) {
	:root {
		--text-color: #333333;
		--bg-color: #ffffff;
	}
}

.adaptive-color {
	color: var(--text-color) !important;
}

/* Стили для основного контейнера */
.template-page {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: flex-start;
	height: 100vh; /* Занимает всю высоту экрана */
	box-sizing: border-box;
	background-color: var(--bg-color) !important; /* Цвет фона страницы */
}

/* Стили для логотипа */
.logo {
	max-width: 250px; /* Максимальная ширина */
	max-height: 150px; /* Максимальная высота */
	margin-bottom: 20px; /* Отступ снизу */
}

/* Стили для текста с вопросом */
.question {
	margin-bottom: 20px; /* Отступ снизу */
	font-size: 24px; /* Размер шрифта */
	color: #333; /* Цвет текста */
	text-align: center; /* Выравнивание текста по центру */
}

.positive-feedback-button,
.negative-feedback-button {
	width: 50%;
	padding: 10px 20px; /* Поля внутри кнопок */
	border: none; /* Убрать границу */
	color: white; /* Цвет текста */
	font-size: 18px; /* Размер шрифта */
	cursor: pointer; /* Курсор в виде руки */
	margin: 10px; /* Отступы вокруг кнопок */
	border-radius: 5px; /* Скругление углов */
	transition: background-color 0.3s ease; /* Плавная смена цвета фона */
}

/* Стили для зеленой кнопки */
.positive-feedback-button {
	background-color: #68b36b; /* Зеленый фон */
}

/* Стили для красной кнопки */
.negative-feedback-button {
	background-color: #e57373; /* Красный фон */
}

/* Стили при наведении курсора на кнопки */
.positive-feedback-button:hover,
.negative-feedback-button:hover {
	opacity: 0.8; /* Немного прозрачности при наведении */
}

/* Стили для смайлика */
.smiley {
	font-size: 124px; /* Большой размер смайлика */
	margin: 20px 0; /* Отступы сверху и снизу */
}

/* Стили для заголовка 'Оставьте отзыв о нашей работе' */
.feedback-text strong {
	font-size: 22px; /* Увеличенный размер шрифта для выделенного текста */
	display: block; /* Заголовок на новой строке */
}

/* Стили для текста, предшествующего кнопкам */
.feedback-text {
	margin: 10px 0; /* Отступы сверху и снизу для текста */
	font-size: 18px; /* Размер шрифта */
	color: #333; /* Цвет текста */
	text-align: center; /* Выравнивание текста по центру */
}

.feedback-buttons-container {
	display: flex; /* Используем flexbox для расположения элементов */
	flex-wrap: wrap; /* Позволяет элементам обтекать и переходить на новую строку */
	justify-content: space-between; /* Распределяет пространство между элементами */
	align-items: center; /* Центрирует элементы по вертикали */
	max-width: 90%;
	margin: auto; /* Центрирование контейнера на странице */
}

/* Общие стили для всех кнопок обратной связи */
.feedback-button {
	width: 40%; /* Ширина каждой кнопки */
	display: inline-block; /* Элементы будут в одну строку и смогут принимать ширину и отступы */
	padding: 10px 15px; /* Внутренние отступы */
	margin: 10px; /* Внешние отступы */
	border: 2px solid #ccc; /* Тонкая граница со спокойным цветом */
	border-radius: 5px; /* Скругление углов */
	text-align: center; /* Выравнивание текста по центру */
	font-size: 20px; /* Размер шрифта */
	color: #333; /* Цвет текста */
	background-color: #fff; /* Белый фон */
	cursor: pointer; /* Курсор в виде руки */
	text-decoration: none; /* Убрать подчёркивание текста */
	transition: background-color 0.3s, border-color 0.3s; /* Плавная смена фона и границы при наведении */
}

/* Стили для кнопок при наведении курсора */
.feedback-button:hover {
	border-color: #999; /* Цвет границы при наведении */
	background-color: #f0f0f0; /* Цвет фона при наведении */
}

/* Стили для кнопки Яндекс */
.feedback-button.yandex {
	border-color: #bf1650; /* Малиновый цвет границы */
	color: #bf1650; /* Малиновый цвет текста */
}

/* Стили для кнопки Яндекс при наведении */
.feedback-button.yandex:hover {
	background-color: #bf1650; /* Малиновый цвет фона при наведении */
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.google {
	border-color: #4285f4;
	color: #4285f4; /* Малиновый цвет текста */
}

.feedback-button.google:hover {
	background-color: #4285f4;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.twoGis {
	border-color: #39b54a;
	color: #39b54a; /* Малиновый цвет текста */
}

.feedback-button.twoGis:hover {
	background-color: #39b54a;
	color: white; /* Белый цвет текста при наведении */
}

/* Пример фильтра для изменения цвета иконки */
.feedback-button.twoGis .feedback-icon {
	width: 20px; /* Установка ширины иконки */
	height: 20px; /* Установка высоты иконки */
	filter: invert(45%) sepia(100%) saturate(600%) hue-rotate(88deg) brightness(90%) contrast(90%);
}

/* Изменение фильтра при наведении для кнопки, чтобы иконка стала белой */
.feedback-button.twoGis:hover .feedback-icon {
	filter: brightness(0) invert(1);
}

.feedback-button.vk {
	border-color: #4c75a3;
	color: #4c75a3; /* Малиновый цвет текста */
}

.feedback-button.vk:hover {
	background-color: #4c75a3;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.prodoctorov {
	border-color: #ff5722;
	color: #ff5722; /* Малиновый цвет текста */
}

.feedback-button.prodoctorov:hover {
	background-color: #ff5722;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.iReccomend {
	border-color: #ffc107;
	color: #ffc107; /* Малиновый цвет текста */
}

.feedback-button.iReccomend:hover {
	background-color: #ffc107;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.zoon {
	border-color: #9c27b0;
	color: #9c27b0; /* Малиновый цвет текста */
}

.feedback-button.zoon:hover {
	background-color: #9c27b0;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.otzovik {
	border-color: #f44336;
	color: #f44336; /* Малиновый цвет текста */
}

.feedback-button.otzovik:hover {
	background-color: #f44336;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.yell {
	border-color: #00bcd4;
	color: #00bcd4; /* Малиновый цвет текста */
}

.feedback-button.yell:hover {
	background-color: #00bcd4;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.tripadvisor {
	border-color: #1e824c;
	color: #1e824c; /* Малиновый цвет текста */
}

.feedback-button.tripadvisor:hover {
	background-color: #1e824c;
	color: white; /* Белый цвет текста при наведении */
}

.feedback-button.whatsApp {
	border-color: #25d366;
	color: #25d366; /* Малиновый цвет текста */
}

.feedback-button.whatsApp:hover {
	background-color: #25d366;
	color: white; /* Белый цвет текста при наведении */
}

/* Стили для заголовка 'Написать директору' */
.director-title {
	font-size: 24px; /* Размер шрифта */
	margin: 10px 0 20px 0; /* Отступы */
	font-weight: bold; /* Жирный шрифт */
}

/* Стили для параграфов с описанием */
.description {
	margin-bottom: 10px; /* Отступ снизу для каждого параграфа */
	color: #333; /* Цвет текста */
	font-size: 18px; /* Размер шрифта */
}

/* Стили для текстовых полей ввода */
.feedback-form input[type="text"],
.feedback-form textarea {
	width: 100%; /* Ширина полей ввода */
	padding: 10px; /* Внутренние отступы */
	margin-bottom: 15px; /* Отступ снизу */
	border: 1px solid #ccc; /* Граница */
	border-radius: 5px; /* Скругление углов */
}

/* Стили для большого текстового поля */
.feedback-form textarea {
	height: 100px; /* Высота текстового поля */
}

/* Стили для кнопки 'Отправить' */
.feedback-form button {
	padding: 10px 15px; /* Внутренние отступы */
	border: none; /* Убрать границу */
	color: white; /* Белый цвет текста */
	background-color: #db7093; /* Малиновый фон */
	border-radius: 5px; /* Скругление углов */
	cursor: pointer; /* Курсор в виде руки */
	font-size: 18px; /* Размер шрифта */
	transition: background-color 0.3s; /* Плавная смена фона */
}

/* Стили для кнопки 'Отправить' при наведении */
.feedback-form button:hover {
	background-color: #c06090; /* Темно-малиновый фон при наведении */
}
