.admin-dashboard {
	height: 100vh;
	background-color: #f0f4f8; /* или другой цвет, сочетающийся со светлой темой */
}

a {
	color: #1a237e; /* или другой цвет, подходящий под вашу палитру */
	text-decoration: none; /* убирает стандартное подчеркивание */
}

.admin-dashboard a:hover {
	text-decoration: underline; /* добавляет подчеркивание при наведении */
}

.form {
	display: flex;
	align-items: center;
	gap: 10px;
	margin-left: 20px;
}
@media (max-width: 768px) {
	.form {
		margin-left: 0;
	}
}

@media (max-width: 576px) {
	.linkMobile {
		display: none;
	}
}
