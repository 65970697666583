.not-found {
	display: flex;
	align-items: center;
	justify-content: center;
	height: 100vh;
	text-align: left;
}

.not-found h1 {
	font-size: 10em;
	margin: 0;
	color: #e74c3c;
}

.not-found .text {
	margin-left: 20px;
}

.not-found p {
	font-size: 1.2em;
	color: #555;
	margin: 10px 0;
}

.not-found .link {
	color: #f08080;
	text-decoration: none;
	margin-top: 20px;
}

.not-found .link:hover {
	color: #c76363;
}

@media (max-width: 768px) {
	.not-found {
		flex-direction: column;
		align-items: center;
		justify-content: center;
	}
	.not-found .text {
		text-align: center;
		margin-left: 0;
		padding: 0 20px;
	}
}
